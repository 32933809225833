import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import { Layout } from '../components/organisms';
import { PageWrapper } from '../components/atoms';
import { breakpoints, colors, dimensions } from '../styles/variables';
import { Footer, ProductCategory, ProductProps } from '../components/molecules';

const Content = styled.div`
  padding-top: 72px;
  padding-bottom: ${dimensions.space.fourtyEight};
  flex-grow: 1;
`;

const ColorOverlay = styled.div`
  background-color: ${colors.purple};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  z-index: -1;
`;

const BackgroundImage = styled(Img)`
  position: fixed !important;
  z-index: -1;
  object-fit: cover;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Title = styled.h1`
  color: ${colors.white};
  font-size: 50px;
  line-height: 61px;
  text-transform: uppercase;
  text-align: center;
  margin-top: ${dimensions.space.fourtyEight};
  margin-bottom: ${dimensions.space.fourtyEight};
`;

const Categories = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;

  @media screen and (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
`;

const BlankTile = styled.div`
  display: none;

  @media screen and (min-width: 1172px) {
    display: block;
  }
`;

type Props = {
  data: {
    strapiProductPage: {
      title: string;
      image: {
        localFile: {
          childImageSharp: {
            fluid: FluidObject;
          };
        };
      };
    };
    allStrapiProduct: {
      edges: [
        {
          node: ProductProps;
        },
      ];
    };
    allStrapiCategory: {
      edges: [
        {
          node: {
            id: string;
            title: string;
            color: string;
            products: { id: number }[];
          };
        },
      ];
    };
  };
};

export default function SolutionsPage({ data }: Props) {
  return (
    <Layout title={data.strapiProductPage.title} description="Des solutions adaptées à vos besoins">
      <Content>
        <BackgroundImage aria-hidden fluid={data.strapiProductPage.image.localFile.childImageSharp.fluid} />
        <ColorOverlay aria-hidden />
        <PageWrapper>
          <Title>{data.strapiProductPage.title}</Title>
          <Categories>
            {data.allStrapiCategory.edges.map((cat, idx) => (
              <>
                <ProductCategory
                  key={cat.node.id}
                  {...cat.node}
                  products={cat.node.products.map(
                    (p) => data.allStrapiProduct.edges.find((e) => e.node.strapiId === p.id)?.node,
                  )} // Ici, je passe par là car le gatsbyPath ne semble pas fonctionner dans une collection nestée
                />
                {idx === 0 || idx === 4 ? <BlankTile aria-hidden /> : null}
              </>
            ))}
          </Categories>
        </PageWrapper>
      </Content>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query {
    strapiProductPage {
      title
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    allStrapiProduct {
      edges {
        node {
          id
          strapiId
          name
          url: gatsbyPath(filePath: "/solutions/{StrapiProduct.name}")
        }
      }
    }

    allStrapiCategory(sort: { fields: order }) {
      edges {
        node {
          id
          title
          color
          products {
            id
          }
        }
      }
    }
  }
`;
